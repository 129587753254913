import { setRemoteDefinitions } from '@nx/react/mf';
import { environment as libEnvironment } from '@operations-os-ui/environment';

import { environment } from './environments/environment';

const devRemotes = process.env['NX_MF_DEV_REMOTES'];

let compactEnvironment = environment.environment;
switch (environment.environment) {
  case 'development':
    compactEnvironment = 'dev';
    break;
  case 'staging':
    compactEnvironment = 'stg';
    break;
  case 'production':
    compactEnvironment = 'prd';
    break;
}

fetch(
  environment.production
    ? `/${compactEnvironment}/host/latest/assets/module-federation.manifest.json`
    : '/assets/module-federation.manifest.json'
)
  .then((res) => res.json() as unknown as Record<string, string>)
  .then((definitions) => {
    if (environment.production) {
      Object.keys(definitions).forEach((key) => {
        definitions[key] = `${libEnvironment.baseOrigin}/${compactEnvironment}/${key}/latest`;
      });
    } else if (devRemotes) {
      const maxPort = Math.max(...Object.values(definitions).map((url) => parseInt(url.split(':')[2], 10))) + 1;
      Object.entries(definitions).forEach(([key, value]) => {
        if (!devRemotes.includes(key)) {
          definitions[key] = `${value.split(':')[1]}:${maxPort}/${key}`;
        }
      });
    }
    definitions['crm_export'] = definitions['export'];
    delete definitions['export'];
    setRemoteDefinitions(definitions);
  })
  .then(() => import('./bootstrap').catch((err) => console.error(err)));
