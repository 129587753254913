export const environment = {
  production: true,
  adminUrl: 'https://admin.zoominfo.com',
  baseURL: 'https://app.zoominfo.com/ziapi/opsos/api/',
  baseOrigin: 'https://operationsos.zoominfo.com',
  baseAssetsURL: 'https://assets.zoominfo.co',
  amchartsLicense: 'AM5C439303090',
  muiProKey:
    'fb3a80eb858392ea3a161a85877443b8Tz05Nzc2OCxFPTE3NTc1MTQ3NjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==',
  pusherKey: '6817768e79f6dde03e4a',
  pusherCluster: 'mt1',
  ziLoginUrl: 'https://login.zoominfo.com',
  ziBaseUrl: 'https://app.zoominfo.com',
};
